import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "./../less/styles.scss";
import "./../less/footer.scss";


import { Container, Row, Col, ul } from "react-bootstrap" 
import  FaFacebookF  from "../images/faFacebookF";
import  FaInstagram  from "../images/FaInstagram";
import  FaTwitter  from "../images/FaTwitter";
const MainLogo = "../images/d-golden-main-logo.png";
     

function Footer() {
	return (
    <div className="footer">
        <div className="footer-wrapper">
        <div className="row">
            <div className="col-sm-4">
                <div className="footer-content">
                <a class="footer-brand" href="#">  <StaticImage src={MainLogo} quality={60} formats={["auto", "webp", "avif"]} /></a>
                <p>Let us take care of your next adventure to Belize. Let your feet
                    wander, your eyes marvel, and your soul ignite with D-Golden Tours!</p>
                    <ul className="social">
                                <li>
                                    <a href="https://www.facebook.com/dgoldentours/" target="_blank"><FaFacebookF /></a>
                                    <a href="https://www.instagram.com/dgolden_tours/" target="_blank"><FaInstagram /></a>
                                 
                                </li>
                            
                    </ul>
                </div>
            </div>
            <div className="col-sm-4 second">
                <div className="footer-content">
                    <h1>Contact Us</h1>
                    <p>
                        TEL:
                    </p>
                    <a href="tel:5016501702">+(501) 650-1702</a>
                    <p>
                        EMAIL:
                    </p>
                    <a href="mailto:DGoldentours@gmail.com">DGoldentours@gmail.com</a>
                    <p>
                        OFFICE HOURS:
                    </p>
                    <a href="">Mon - Sun, 8:00am to 4:00pm CST</a>
                </div>
            </div>
            <div className="col-sm-4">
               <div className="footer-content">
        
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488244.6240726738!2d-88.86204773367018!3d17.05253121441613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f5d98ffd2b0e263%3A0x65ac9514e24af43e!2sD-Golden%20Tours!5e0!3m2!1sen!2sbz!4v1700796599237!5m2!1sen!2sbz" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        </div>
        <div className="footer-copyright">
            <p>Copyright © {new Date().getFullYear()} D-Golden Tours. All Rights Reserved. Belize. <a href="https://belbytedesigns.com/" target="_blank"> [ Powered by Belbyte Designs ]</a></p>
        </div>
        <div class="elfsight-app-2ccedc37-a580-4dad-8429-06fe97d320a4"></div> 
	</div>
                              
                               
    );            
}

export default Footer;

